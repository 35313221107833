import React, { useEffect, useState } from 'react'
import { getScore } from './services/Service';
import { useNavigate } from 'react-router-dom';

export default function Score() {
  const navigate = useNavigate();
  const [tdata, setTdata] = useState([]);
  const [totalPage, setTotalPage] = useState([]);
  const score = async () => {
    const resp = await getScore();
    if (resp && resp.data && resp.data.status === 1) {

      setTdata(resp.data);
      const totPage = resp.data.others.total_page
      let tPage = [];
      for (let i = 0; i < totPage; i++) {
        tPage.push(i + 1);
      }
      setTotalPage(tPage);
    } else {
      console.log(resp.data.message);
    }
  }

  useEffect(() => {
    score();
  }, [])


  const onclickhandler = () => {
    window.open('https://sorteamos.es/', '_blank');
  }

  const reButtonHandler = () =>{
    window.open('https://sorteamosroadracer.es/car/index.html', '_blank');
  }
  return (
    <>
      <div className="mainContainer">
        <div className="row justify-center" style={{ margin: 0, cursor: 'pointer' }} >
          <img
            src="assets/img/logo_white.svg"
            style={{ width: 130, marginTop: "1%", marginBottom: 6 }}
            alt="Description of the image"
          />
        </div>
        <div className="row justify-center" style={{ margin: 0, padding: 0 }}>
          <div
            className="p-abslute innter-table"
            style={{ width: "85%", height: "80%" }}
          >
            <div style={{ maxHeight: 480, overflowY: "auto" }}>
              <table className="table table-bordered text-center">
                <thead>
                  <tr className="bg-img" style={{ color: "yellow" }}>
                    <th scope="col">Posición</th>
                    <th scope="col">Jugador</th>
                    <th scope="col">Puntuación</th>
                  </tr>
                </thead>
                <tbody>
                  {tdata && tdata.data && tdata.data.length > 0 && tdata.data.map((data, index) => (
                    <tr className="innter-table bg-img">
                      <th scope="row">{((tdata.others.current_page - 1) * 10) + index + 1}</th>
                      <td>{data.user_name}</td>
                      <td>{data.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}
