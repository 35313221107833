import http from "./http";

export const getScore = async (data) =>{
    try {
        const resp = await http.post('sorteamos/apis/api/score',data);
        return resp;
    } catch (error) {
        console.log(error);
    }
}

export const getcsv = async () =>{
    try {
        const resp = await http.post('sorteamos/apis/api/alldata');
        return resp;
    } catch (error) {
        console.log(error);
    }
}