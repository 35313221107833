import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Score from './Score';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
<>
<BrowserRouter >
          <Routes>
            <Route path="/score" element={<Score />} />
            <Route path="*" element={<Home />} />
            </Routes>
      </BrowserRouter>
{/* <Score />
<Home /> */}
{/* <Home /> */}
</>
  );
}

export default App;
