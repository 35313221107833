import React, { useEffect, useState } from 'react'
import { getScore, getcsv } from './services/Service';



export default function Home() {
    const [tdata, setTdata] = useState([]);
    const [totalPage, setTotalPage] = useState([]);
    

    const [login, setLogin] = useState(0);
    const [error, setError] = useState({ userName: '', password: '' });
    const [fdata, setFdata] = useState({ userName: '', password: '' });
    const [loder,setLoder] = useState(false);

    const changeHanler = (e) => {
        const { name, value } = e.target;
        setFdata((pre) => ({ ...pre, [name]: value }));
        setError((pre) => ({ ...pre, [name]: '' }));
    }


    const score = async () => {
        const resp = await getScore();
        if (resp && resp.data && resp.data.status === 1) {
            setTdata(resp.data);
            const totPage = resp.data.others.total_page
            let tPage = [];
            for (let i = 0; i < totPage; i++) {
                tPage.push(i + 1);
            }
            setTotalPage(tPage);
        } else {
            console.log(resp.data.message);
        }
    }

    const pageHandler = async (value) => {
        let data = { "page": value }
        const resp = await getScore(data);
        if (resp && resp.data && resp.data.status === 1) {
            setTdata(resp.data);
            const totPage = resp.data.others.total_page
            let tPage = [];
            for (let i = 0; i < totPage; i++) {
                tPage.push(i + 1);
            }
            setTotalPage(tPage);
        } else {
            console.log(resp.data.message);
        }
    }

    useEffect(() => {
        score();
    }, []);


    const submitHandler = (e) => {
        e.preventDefault();
        const form = e.target; // Get the form element
        const formData = new FormData(form);
        const userName = formData.get('userName');
        const password = formData.get('password');
        let isValid = 1;
        if (userName == '') { setError((pre) => ({ ...pre, userName: 'Required' })); isValid = 2; }
        if (password == '') { setError((pre) => ({ ...pre, password: 'Required' })); isValid = 3; }
        if (userName && userName != 'admin') { setError((pre) => ({ ...pre, userName: 'Invalid User Name' })); isValid = 2; }
        if (password && password != 'admin@123') { setError((pre) => ({ ...pre, password: 'Invalid Password' })); isValid = 2; }
        if (isValid === 1) {
            setLogin(1);
        }
    }

    const downloadCSV = async () => {
        setLoder(true);
        const resp = await getcsv();
        if (resp && resp.data && resp.data.status === 1) {
            setLoder(false);
        const csvContent = "Name,Email,Score,Rank\n" + resp.data.data.map((data, index) =>
            `${data.user_name},${data.email},${data.score},${ index + 1}`
        ).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv' });
        // Create a download link and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = 'scores.csv';
        downloadLink.click();
    }

    }
    return (
        <>
       {loder && <div className='lodermy'><div class="spinner-border text-success" role="status"></div></div>}
            {login != 0 ?
                <section id="hero">
                    <div className="container login-container ">
                        <div className="row" style={{ height: '97vh' }}>
                            <div className="login-form-1">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <img style={{ width: '40px', height: '40px' }} src="assets/img/logo.png" alt="..." />
                                    </div>
                                    <div className='col-md-6'>
                                    <button className="btn btn-primary" style={{float: 'inline-end', fontSize: '10px'}} onClick={downloadCSV}>Download CSV</button>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Score</th>
                                                <th scope="col">Rank</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tdata && tdata.data && tdata.data.length > 0 && tdata.data.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data.user_name}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.score}</td>
                                                    <td>{((tdata.others.current_page - 1) * 10) + index + 1}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='painate'>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                {tdata && tdata.others && tdata.others.current_page && tdata.others.current_page !== 1 ?
                                                    <button className="paginetion-btn page-link" onClick={() => pageHandler(tdata.others.current_page - 1)}>Previous</button>
                                                    :
                                                    <button className="paginetion-btn page-link" disabled >Previous</button>
                                                }
                                            </li>
                                            {totalPage.map((value, key) => (
                                                <li key={key} className="page-item">
                                                    {tdata.others.current_page === value ? <button className="paginetion-btn page-link" style={{ color: '#ffc107' }} onClick={() => pageHandler(value)}>{value}</button> : <button className="paginetion-btn page-link" onClick={() => pageHandler(value)}>{value}</button>}

                                                </li>
                                            ))}
                                            <li className="page-item">
                                                {tdata && tdata.others && tdata.others.current_page && tdata.others.current_page !== tdata.others.total_page ?
                                                    <button className="paginetion-btn page-link" onClick={() => pageHandler(tdata.others.current_page + 1)}>Next</button>
                                                    :
                                                    <button className="paginetion-btn page-link" disabled >Next</button>
                                                }
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section id="hero">
                    <div className="container login-container">
                        <div className="row" style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <div style={{ width: '40%', margin: "auto", position: "absolute" }}>
                                <div className='login-form-1'>
                                    <div className=' text-center'>
                                        <img style={{ width: '70px', height: '70px' }} src="assets/img/logo.png" alt="..." />
                                    </div>
                                    <div className="">

                                        <form onSubmit={submitHandler} >
                                            <div className="form-group mb-2">
                                                <input type="text" className="form-control" onChange={(e) => changeHanler(e)} placeholder="User Name *" name="userName" />
                                                <small className="text-danger">{error.userName}</small>
                                            </div>
                                            <div className="form-group mt-3">
                                                <input type="password" className="form-control" onChange={(e) => changeHanler(e)} placeholder="Your Password *" name="password" />
                                                <small className="text-danger" >{error.password}</small>
                                            </div>
                                            <div className="form-group mt-3">
                                                <button type="submit" className="btnSubmit float-end"> Login </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
